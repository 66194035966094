var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          ref: "inquiryLogPopover",
          attrs: {
            placement: "bottom-start",
            width: "340",
            "popper-class": "talentpool-popover talentpool-popover-inquiryLog",
            trigger: "hover",
            "visible-arrow": false,
            "open-delay": 700,
            offset: 500,
          },
          on: {
            show: function ($event) {
              return _vm.getInquiryLogs(_vm.resume.resumeListItem)
            },
          },
        },
        [
          _c(
            "span",
            { attrs: { slot: "reference" }, slot: "reference" },
            [
              _c("font-icon", {
                staticClass: "table-body-icon-big icon-inquiry",
                attrs: { href: "#icon-talent_inquiry" },
              }),
              _c("span", [
                _vm._v(
                  " " + _vm._s(_vm.resume.resumeListItem.inquiryLogsCount)
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c(
                "div",
                { staticClass: "table-popper-header inquiry-log-header" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.resume.resumeListItem.realName) +
                      " 沟通寻访\n            "
                  ),
                  !_vm.shareLogId && _vm.type != "trakingList"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-plus-outline add-itap-icon",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.resumeOperation("addVisit", {
                                resume: _vm.resume,
                              })
                            },
                          },
                        },
                        [_vm._v("\n                添加\n            ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "只看自己", name: "seeMy" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "看全部的", name: "seeAll" },
                  }),
                ],
                1
              ),
              _vm.activeTab == "seeMy"
                ? _c(
                    "div",
                    { staticClass: "inquery-log-wrap" },
                    [
                      _vm._l(
                        _vm.resume.resumeListItem.myResumeInquiryLog,
                        function (resumeItem, index) {
                          return _c(
                            "div",
                            {
                              key: "mylog_" + index,
                              staticClass: "inquery-log-item",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "record-hd" },
                                [
                                  _c("avatar", {
                                    attrs: {
                                      enableCard: true,
                                      enableLink: true,
                                      src: resumeItem.creatorAvatar,
                                      userId: resumeItem.creatorId,
                                    },
                                  }),
                                  _c("div", { staticClass: "record-title" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "record-info",
                                        attrs: {
                                          title:
                                            resumeItem.creatorRealName +
                                            "@" +
                                            resumeItem.creatorNickName,
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "record-user",
                                          domProps: {
                                            textContent: _vm._s(
                                              resumeItem.creatorRealName
                                            ),
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "record-number" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                "@" + resumeItem.creatorNickName
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "record-date" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("date")(resumeItem.updated)
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "record-hide-status",
                                      class:
                                        resumeItem.hideStatus !== undefined &&
                                        resumeItem.hideStatus === 0
                                          ? "private"
                                          : "",
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            resumeItem.hideStatus !==
                                              undefined &&
                                              resumeItem.hideStatus === 0
                                              ? "私"
                                              : "公"
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "pre",
                                {
                                  staticClass: "inquiry-log-content",
                                  domProps: {
                                    textContent: _vm._s(resumeItem.content),
                                  },
                                },
                                [_vm._v("                ")]
                              ),
                              resumeItem.audioRecordUrl
                                ? _c("record-listen", {
                                    attrs: {
                                      "audio-url": resumeItem.audioRecordUrl,
                                      duration: resumeItem.audioDuration / 60,
                                      progressWidth: 140,
                                      controlBtnWidth: 10,
                                    },
                                  })
                                : _vm._e(),
                              resumeItem.jobHoppingOccasion ||
                              resumeItem.employerInfo ||
                              resumeItem.expectSalary
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "inquiry-log-detail",
                                      style: resumeItem.audioRecordUrl
                                        ? "margin-top: 5px;"
                                        : "",
                                    },
                                    [
                                      resumeItem.jobHoppingOccasion
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "inquiry-log-detail-item",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        跳槽时机：" +
                                                  _vm._s(
                                                    resumeItem.jobHoppingOccasion
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      resumeItem.employerInfo
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "inquiry-log-detail-item",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        雇主画像：" +
                                                  _vm._s(
                                                    resumeItem.employerInfo
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      resumeItem.expectSalary
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "inquiry-log-detail-item",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        期望报酬：" +
                                                  _vm._s(
                                                    resumeItem.expectSalary
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.userID === resumeItem.creatorId &&
                                        !_vm.shareLogId &&
                                        _vm.type != "trakingList",
                                      expression:
                                        "userID === resumeItem.creatorId && !shareLogId && type != 'trakingList'",
                                    },
                                  ],
                                  staticClass: "inquiry-log-footer",
                                },
                                [
                                  resumeItem.isValidInquiry
                                    ? _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editInquiry(resumeItem)
                                            },
                                          },
                                        },
                                        [
                                          _c("font-icon", {
                                            attrs: { href: "#icon-edit" },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "edit-text" },
                                            [_vm._v("编辑")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteInquiry(
                                            resumeItem.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-icon", {
                                        attrs: { href: "#icon-ic_rubbish" },
                                      }),
                                      _c("span", { staticClass: "edit-text" }, [
                                        _vm._v("删除"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm.resume.resumeListItem.myResumeInquiryLog == 0
                        ? _c("div", { staticClass: "empty-data" }, [
                            _c("span", { staticClass: "empty-img" }),
                            _c("p", { staticClass: "empty-text" }, [
                              _vm._v("暂无数据"),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.resume.resumeListItem.myResumeInquiryLog &&
                      _vm.resume.resumeListItem.myResumeInquiryLog.length <
                        _vm.resume.MyTotal
                        ? _c(
                            "div",
                            {
                              staticClass: "inquiry-bottom",
                              on: { click: _vm.handleShowMore },
                            },
                            [
                              _c("span", { staticClass: "inquiry-show-more" }, [
                                _vm._v("加载更多"),
                                _c("i", {
                                  staticClass: "el-icon-d-arrow-right",
                                }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _c(
                    "div",
                    { staticClass: "inquery-log-wrap" },
                    [
                      _vm._l(
                        _vm.resume.resumeListItem.allResumeInquiryLog,
                        function (resumeItem, index) {
                          return _c(
                            "div",
                            {
                              key: "log_" + index,
                              staticClass: "inquery-log-item",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "record-hd" },
                                [
                                  _c("avatar", {
                                    attrs: {
                                      enableCard: true,
                                      enableLink: true,
                                      src: resumeItem.creatorAvatar,
                                      userId: resumeItem.creatorId,
                                    },
                                  }),
                                  _c("div", { staticClass: "record-title" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "record-info",
                                        attrs: {
                                          title:
                                            resumeItem.creatorRealName +
                                            "@" +
                                            resumeItem.creatorNickName,
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "record-user",
                                          domProps: {
                                            textContent: _vm._s(
                                              resumeItem.creatorRealName
                                            ),
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "record-number" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                "@" + resumeItem.creatorNickName
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "record-date" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("date")(resumeItem.updated)
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "record-hide-status",
                                      class:
                                        resumeItem.hideStatus !== undefined &&
                                        resumeItem.hideStatus === 0
                                          ? "private"
                                          : "",
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            resumeItem.hideStatus !==
                                              undefined &&
                                              resumeItem.hideStatus === 0
                                              ? "私"
                                              : "公"
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "pre",
                                {
                                  staticClass: "inquiry-log-content",
                                  domProps: {
                                    textContent: _vm._s(resumeItem.content),
                                  },
                                },
                                [_vm._v("                ")]
                              ),
                              resumeItem.audioRecordUrl
                                ? _c("record-listen", {
                                    attrs: {
                                      "audio-url": resumeItem.audioRecordUrl,
                                      duration: +resumeItem.audioDuration / 60,
                                      progressWidth: 140,
                                      controlBtnWidth: 10,
                                    },
                                  })
                                : _vm._e(),
                              resumeItem.jobHoppingOccasion ||
                              resumeItem.employerInfo ||
                              resumeItem.expectSalary
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "inquiry-log-detail",
                                      style: resumeItem.audioRecordUrl
                                        ? "margin-top: 5px;"
                                        : "",
                                    },
                                    [
                                      resumeItem.jobHoppingOccasion
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "inquiry-log-detail-item",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        跳槽时机：" +
                                                  _vm._s(
                                                    resumeItem.jobHoppingOccasion
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      resumeItem.employerInfo
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "inquiry-log-detail-item",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        雇主画像：" +
                                                  _vm._s(
                                                    resumeItem.employerInfo
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      resumeItem.expectSalary
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "inquiry-log-detail-item",
                                            },
                                            [
                                              _vm._v(
                                                "\n                        期望报酬：" +
                                                  _vm._s(
                                                    resumeItem.expectSalary
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.userID === resumeItem.creatorId &&
                                        !_vm.shareLogId &&
                                        _vm.type != "trakingList",
                                      expression:
                                        "userID === resumeItem.creatorId && !shareLogId && type != 'trakingList'",
                                    },
                                  ],
                                  staticClass: "inquiry-log-footer",
                                },
                                [
                                  resumeItem.isValidInquiry
                                    ? _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editInquiry(resumeItem)
                                            },
                                          },
                                        },
                                        [
                                          _c("font-icon", {
                                            attrs: { href: "#icon-edit" },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "edit-text" },
                                            [_vm._v("编辑")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteInquiry(
                                            resumeItem.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-icon", {
                                        attrs: { href: "#icon-ic_rubbish" },
                                      }),
                                      _c("span", { staticClass: "edit-text" }, [
                                        _vm._v("删除"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm.resume.resumeListItem.allResumeInquiryLog &&
                      _vm.resume.resumeListItem.allResumeInquiryLog.length <
                        _vm.resume.total
                        ? _c(
                            "div",
                            {
                              staticClass: "inquiry-bottom",
                              on: { click: _vm.handleShowMore },
                            },
                            [
                              _c("span", { staticClass: "inquiry-show-more" }, [
                                _vm._v("加载更多"),
                                _c("i", {
                                  staticClass: "el-icon-d-arrow-right",
                                }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "540px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确认删除寻访记录？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.remove } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }