<template>
    <div>
    <el-popover
        ref="inquiryLogPopover"
        placement="bottom-start"
        width="340"
        popper-class="talentpool-popover talentpool-popover-inquiryLog"
        trigger="hover"
        :visible-arrow="false"
        @show="getInquiryLogs(resume.resumeListItem)"
        :open-delay="700"
        :offset="500">
        <span slot="reference">
            <font-icon class="table-body-icon-big icon-inquiry" href="#icon-talent_inquiry"></font-icon>
            <span>&nbsp;{{resume.resumeListItem.inquiryLogsCount}}</span>
        </span>
        <!-- <div v-show="resume.resumeListItem.resumeInquiryLog" v-loading="!resume.resumeListItem.resumeInquiryLog.resumeId"> -->
        <div v-loading="loading">
            <div class="table-popper-header inquiry-log-header">
                {{resume.resumeListItem.realName}} 沟通寻访
                <el-button
                    v-if="!shareLogId && type != 'trakingList'"
                    icon="el-icon-circle-plus-outline add-itap-icon"
                    @click="resumeOperation('addVisit', {resume: resume})"
                >
                    添加
                </el-button>
            </div>
            <el-tabs v-model="activeTab" @tab-click="handleClick">
                <el-tab-pane label="只看自己" name="seeMy"></el-tab-pane>
                <el-tab-pane label="看全部的" name="seeAll"></el-tab-pane>
            </el-tabs>

            <!-- 只看自己 -->
            <div class="inquery-log-wrap" 
                v-if="activeTab == 'seeMy'">
                <div class="inquery-log-item" 
                    v-for="(resumeItem, index) in resume.resumeListItem.myResumeInquiryLog"
                    :key="'mylog_' + index"
                    >
                    <!-- <div class="inquiry-log-userInfo">
                        <img :src="resumeItem.creatorAvatar" alt="">
                        <span class="name">{{ resumeItem.creatorRealName + '@' + resumeItem.creatorNickName }}</span>
                        <span class="time">{{ resumeItem.updated | date }}</span>
                    </div> -->
                    <div class="record-hd">
                        <avatar
                            :enableCard="true"
                            :enableLink="true"
                            :src="resumeItem.creatorAvatar"
                            :userId="resumeItem.creatorId"
                        ></avatar>
                        <div class="record-title">
                            <div class="record-info" :title="resumeItem.creatorRealName + '@' + resumeItem.creatorNickName">
                                <span v-text="resumeItem.creatorRealName" class="record-user"></span>
                                <span class="record-number">{{ '@' + resumeItem.creatorNickName}}</span>
                            </div>
                            <div class="record-date">{{ resumeItem.updated | date }}</div>
                        </div>
                        <div
                            class="record-hide-status"
                            :class="resumeItem.hideStatus !== undefined && resumeItem.hideStatus === 0 ? 'private' : ''">
                            {{resumeItem.hideStatus !== undefined && resumeItem.hideStatus === 0 ? '私' : '公'}}
                        </div>
                    </div>
                    <!-- <div class="inquiry-log-content">{{ resumeItem.content }}</div> -->
                    <pre 
                        class="inquiry-log-content" 
                        v-text="resumeItem.content">
                    </pre>

                    <record-listen
                        v-if="resumeItem.audioRecordUrl"
                        :audio-url="resumeItem.audioRecordUrl"
                        :duration="resumeItem.audioDuration/60"
                        :progressWidth="140"
                        :controlBtnWidth="10"
                    ></record-listen>

                    <div
                        class="inquiry-log-detail"
                        :style="resumeItem.audioRecordUrl ? 'margin-top: 5px;' : ''"
                        v-if="resumeItem.jobHoppingOccasion || resumeItem.employerInfo || resumeItem.expectSalary">
                        <div
                            class="inquiry-log-detail-item"
                            v-if="resumeItem.jobHoppingOccasion"
                        >
                            跳槽时机：{{resumeItem.jobHoppingOccasion}}
                        </div>
                        <div
                            class="inquiry-log-detail-item"
                            v-if="resumeItem.employerInfo"
                        >
                            雇主画像：{{resumeItem.employerInfo}}
                        </div>
                        <div
                            class="inquiry-log-detail-item"
                            v-if="resumeItem.expectSalary"
                        >
                            期望报酬：{{resumeItem.expectSalary}}
                        </div>
                    </div>
                    <div class="inquiry-log-footer" v-show="userID === resumeItem.creatorId && !shareLogId && type != 'trakingList'">
                        <a href="javascript:;" 
                            @click="editInquiry(resumeItem)" 
                            v-if="resumeItem.isValidInquiry">
                            <font-icon href="#icon-edit"></font-icon>
                            <span class="edit-text">编辑</span>
                        </a>
                        <a href="javascript:;" @click="deleteInquiry(resumeItem.id)">
                            <font-icon href="#icon-ic_rubbish"></font-icon>
                            <span class="edit-text">删除</span>
                        </a>
                    </div>

                </div>

                <div class="empty-data" v-if="resume.resumeListItem.myResumeInquiryLog == 0">
                    <span class="empty-img"></span>
                    <p class="empty-text">暂无数据</p>
                </div>

                <div class="inquiry-bottom"
                    v-if="resume.resumeListItem.myResumeInquiryLog && resume.resumeListItem.myResumeInquiryLog.length < resume.MyTotal"
                    @click="handleShowMore">
                    <span class="inquiry-show-more">加载更多<i class="el-icon-d-arrow-right"></i></span>
                </div>
            </div>

            <!-- 看全部的 -->
            <div class="inquery-log-wrap" v-else>
                <div class="inquery-log-item" 
                    v-for="(resumeItem, index) in resume.resumeListItem.allResumeInquiryLog"
                    :key="'log_' + index"
                    >
                    <div class="record-hd">
                        <avatar
                            :enableCard="true"
                            :enableLink="true"
                            :src="resumeItem.creatorAvatar"
                            :userId="resumeItem.creatorId"
                        ></avatar>
                        <div class="record-title">
                            <div class="record-info" :title="resumeItem.creatorRealName + '@' + resumeItem.creatorNickName">
                                <span v-text="resumeItem.creatorRealName" class="record-user"></span>
                                <span class="record-number">{{ '@' + resumeItem.creatorNickName}}</span>
                            </div>
                            <div class="record-date">{{ resumeItem.updated | date }}</div>
                        </div>
                        <div
                            class="record-hide-status"
                            :class="resumeItem.hideStatus !== undefined && resumeItem.hideStatus === 0 ? 'private' : ''">
                            {{resumeItem.hideStatus !== undefined && resumeItem.hideStatus === 0 ? '私' : '公'}}
                        </div>
                    </div>
                    <pre 
                        class="inquiry-log-content" 
                        v-text="resumeItem.content">
                    </pre>
                    <record-listen
                        v-if="resumeItem.audioRecordUrl"
                        :audio-url="resumeItem.audioRecordUrl"
                        :duration="(+resumeItem.audioDuration)/60"
                        :progressWidth="140"
                        :controlBtnWidth="10"
                    ></record-listen>
                    <div
                        class="inquiry-log-detail"
                        :style="resumeItem.audioRecordUrl ? 'margin-top: 5px;' : ''"
                        v-if="resumeItem.jobHoppingOccasion || resumeItem.employerInfo || resumeItem.expectSalary">
                        <div
                            class="inquiry-log-detail-item"
                            v-if="resumeItem.jobHoppingOccasion"
                        >
                            跳槽时机：{{resumeItem.jobHoppingOccasion}}
                        </div>
                        <div
                            class="inquiry-log-detail-item"
                            v-if="resumeItem.employerInfo"
                        >
                            雇主画像：{{resumeItem.employerInfo}}
                        </div>
                        <div
                            class="inquiry-log-detail-item"
                            v-if="resumeItem.expectSalary"
                        >
                            期望报酬：{{resumeItem.expectSalary}}
                        </div>
                    </div>
                    <div class="inquiry-log-footer" v-show="userID === resumeItem.creatorId && !shareLogId && type != 'trakingList'">
                        <a href="javascript:;" 
                            @click="editInquiry(resumeItem)" 
                            v-if="resumeItem.isValidInquiry">
                            <font-icon href="#icon-edit"></font-icon>
                            <span class="edit-text">编辑</span>
                        </a>
                        <a href="javascript:;" @click="deleteInquiry(resumeItem.id)">
                            <font-icon href="#icon-ic_rubbish"></font-icon>
                            <span class="edit-text">删除</span>
                        </a>
                    </div>

                </div>

                <div class="inquiry-bottom"
                    v-if="resume.resumeListItem.allResumeInquiryLog && resume.resumeListItem.allResumeInquiryLog.length < resume.total"
                    @click="handleShowMore">
                    <span class="inquiry-show-more">加载更多<i class="el-icon-d-arrow-right"></i></span>
                </div>
            </div>
        </div>
        
    </el-popover>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="540px">
        <span>确认删除寻访记录？</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="remove">确定</el-button>
        </span>
    </el-dialog>

    </div>
</template>

<script>
    import RecordListen from '#/component/common/record-listen.vue';
    import Avatar from '#/component/common/avatar.vue';
    import moment from 'moment';
    import emitter from '#/js/mixins/emitter.js';
    import popover from '#/js/mixins/popover.js';
    import server from '#/js/service/talentPoolService.js';
    import { candidate as candidateDetailConfig } from '#/js/config/api.json'

    export default {
        name: "inquiryLog",
        componentName: "inquiryLog",
        components: {
            Avatar,
            RecordListen
        },
        mixins: [emitter, popover],

        props: {
            resume: {},
            type: ''
        },
        data(){
            return{
                activeTab: 'seeMy',
                myStart: 0,
                allStart: 0,
                take: 10,

                loading: false,
                dialogVisible: false,
                removeId: '',
            };
        },

        created() {
        },

        computed: {
            // audioDuration() {
            //     return parseInt(this.resume.resumeListItem.resumeInquiryLog.audioDuration) / 60;
            // },
            userID() {
                return this.$store.state.user.userInfo.id;
            },
            urlDelete() {
                return candidateDetailConfig.url_search_record_delete.replace('%p', this.resume.resumeListItem.resumeId);
            },
            verificationToken() {
                return this.$store.state.verificationToken.verificationToken;
            },
            shareLogId() {
                return this.$route.query.shareLogId;
            },
        },

        filters: {
            date(value) {
                return moment(value).format('YYYY-MM-DD H:mm');
            }
        },

        methods: {
            resumeOperation(command, param) {
                this.dispatch('TableWrapper', 'resume-operation', {
                    command: command,
                    data: param
                });
            },
            // showInquiryLog(resume){
            //     if(resume.resumeInquiryLog.resumeId){
            //         return false;
            //     }
            //     server.getLatestInquiryLogs([resume.resumeId])
            //         .then(res => {
            //             res.map(item => {
            //                 resume.resumeInquiryLog = item;
            //             })
            //             this.$nextTick(() => {
            //                 this.popoverShow(this.$refs.inquiryLogPopover);
            //             })
            //         });
            //         this.getInquiryLogs(resume)
            // },
            getInquiryLogs(resume) {
                const _startPage = this.activeTab == 'seeMy' ? this.myStart : this.allStart;
                const params = {
                    start: _startPage * this.take,
                    take: this.take,
                    onlyMyInquiry: this.activeTab == 'seeMy',
                };
                this.loading = true;
                server.getInquiryLogs(resume.resumeId, params)
                    .then(res => {
                        // console.log(res)
                        res.list.map(item => {
                            resume.resumeInquiryLog = item;
                        });
                        if(this.activeTab == 'seeMy'){
                            // this.resume.myResumeInquiryLog = res.list;
                            if(this.myStart > 0) {
                                const _tempLog = [...this.resume.resumeListItem.myResumeInquiryLog, ...res.list];
                                this.$set(this.resume.resumeListItem, 'myResumeInquiryLog', _tempLog);
                            } else {
                                this.$set(this.resume.resumeListItem, 'myResumeInquiryLog', res.list);
                            }
                            this.resume.MyTotal = res.total;
                        } else {
                            // this.resume.resumeInquiryLog = res.list;
                            if(this.allStart > 0) {
                                const _tempLog = [...this.resume.resumeListItem.allResumeInquiryLog, ...res.list];
                                this.$set(this.resume.resumeListItem, 'allResumeInquiryLog', _tempLog);
                            } else {
                                this.$set(this.resume.resumeListItem, 'allResumeInquiryLog', res.list);
                            }
                            this.resume.total = res.total;
                        }
                        
                        this.$nextTick(() => {
                            this.popoverShow(this.$refs.inquiryLogPopover);
                        });
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            handleClick(vc) {
                this.activeTab = vc.name;
                this.getInquiryLogs(this.resume.resumeListItem);
                // if(this.activeTab == 'seeMy' && (!this.resume.resumeListItem.myResumeInquiryLog || this.resume.resumeListItem.myResumeInquiryLog.length == 0)) {
                //     this.getInquiryLogs(this.resume.resumeListItem);
                // }
                // if(this.activeTab == 'seeAll' && (!this.resume.resumeListItem.allResumeInquiryLog || this.resume.resumeListItem.allResumeInquiryLog.length == 0)) {
                    // this.getInquiryLogs(this.resume.resumeListItem);
                // }
            },
            editInquiry(resumeItem) {
                this.resumeOperation('editVisit', {
                    ...resumeItem,
                    resumeId: this.resume.resumeListItem.resumeId,
                    });
            },
            deleteInquiry(val) {
                this.dialogVisible = true;
                this.removeId = val;
            },
            remove() {
                this.dialogVisible = false;
                const params = {
                    id: this.removeId,
                    __RequestVerificationToken:this.verificationToken,
                    candidateId: this.resume.resumeListItem.candidateId,
                };
                _request({
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    url: this.urlDelete,
                    method: "POST",
                    data: params,
                }).then(res => {
                    shortTips(`寻访记录删除成功！`)
                    this.$emit('update-item', this.resume);

                    if(this.activeTab == 'seeMy'){
                        this.myStart = 0;
                    } else {
                        this.allStart = 0;
                    }
                    this.getInquiryLogs(this.resume.resumeListItem);
                }).catch(err => {
                    shortTips(`寻访记录删除失败！`)
                    console.log(err);
                });
            },
            handleShowMore() {
                if(this.activeTab == 'seeMy'){
                    this.myStart++;
                } else {
                    this.allStart++;
                }
                this.getInquiryLogs(this.resume.resumeListItem);
            },

        }
    }
</script>

<style lang="scss" scoped>
.inquery-log-wrap{
    max-height: 220px;
    overflow-y: auto;
    .inquery-log-item{
        height: auto;
        box-sizing: border-box;
        padding: 8px 0;
        border-bottom: 1px solid #ECECEC;
        .inquiry-log-userInfo{
            margin-bottom: 7px;
            img{
                display: inline-block;
                margin-right: 5px;
                width: 26px;
                height: 26px;
                border-radius: 50%;
                border: 1px solid;
            }
            .name,.time{
                font-size: 12px;
            }

            .time{
                margin-left: 10px;
                color: #999;
            }
        }
        .inquiry-log-footer{
            height: auto;
            >a{
                color: #B9B9B9;
                font-size: 12px;
                margin-right: 20px;
                &:active,&:hover{
                    color: #38BC9D;
                }
                .edit-text{
                    margin-left: 9px;
                }
            }
        }
    }
    .inquiry-bottom{
        height: 28px;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        box-sizing: border-box;
        text-align: center;
        line-height: 26px;
        cursor: pointer;
        .inquiry-show-more{
            color: #ccc;
            font-size: 12px;
        }
    }
}

.inquiry-log-content {
    margin-top: 10px;
    max-height: 95px;
    overflow-y: auto;
}

.inquiry-log-detail {
    margin-top: 6px;
    &-item {
        line-height: 22px;
        &::before {
            content: "·";
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            margin-right: 7px;
        }
    }
}
.empty-data {
    margin: 10px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .empty-img {
        display: inline-block;
        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
        background-size: contain;
        width: 150px;
        height: 150px;
    }
    .empty-text {
        line-height: 20px;
        margin-bottom: 0px;
    }
}

.record-hd {
    display: flex;
    .record-title {
        max-width: 210px;
        margin-right: 10px;
        margin-left: 13px;
        color: #333;
        font-size: 14px;
        .record-date{
            color: #B9B9B9;
            font-size: 14px;
            margin-top: 3px;
        }
        .record-info{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .record-hide-status {
        margin-top: 2px;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        text-align: center;
        color: #38bc9d;
        font-size: 22px;
        border: 2px solid #38bc9d;
        border-radius: 4px;
        line-height: 28px;
        transform: scale(0.5);
        transform-origin: left top;
        &.private {
            border-color: #FA765D;
            color: #FA765D;
        }
    }
}
</style>
